import {
  CombinedState, configureStore, Reducer, ReducersMapObject,
} from '@reduxjs/toolkit';
import { userReducer } from 'entities/User';
import { $api } from 'shared/api/api';
import { NavigateFunction } from 'react-router-dom';
import { menuReducer } from 'entities/Menu';
import { createReducerManager } from 'app/providers/StoreProvider/config/reducerManager';
import { rtkApi } from 'shared/api/rtkApi';
import { StateSchema } from './StateSchema';

export function createReduxStore(
  initialState?: StateSchema,
  navigate?: NavigateFunction,
) {
  const rootReducer: ReducersMapObject<StateSchema> = {
    user: userReducer,
    menu: menuReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
  };

  const reducerManager = createReducerManager(rootReducer);

  const store = configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    devTools: true,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: {
          api: $api,
          navigate,
        },
      },
    }).concat(rtkApi.middleware),
  });

  // @ts-ignore
  store.reducerManager = reducerManager;

  return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']
// export type AppDispatch = typeof store.dispatch
// export type AppDispatch = typeof store.dispatch;
